<template>
    <div>
      <a-modal
        v-model="visibleModalManageInsentif"
        title="FORM MANAGE INSENTIF"
        class="modal-shift"
        centered
      >
        <form-insentif
          :dataInsentif="dataInsentif"
          :intensifPegawaiColumns="intensifPegawaiColumns"
          :dataInsentifPegawai="dataInsentifPegawai"
          :statusInsentif="statusInsentif"
          @handle-change="handleChange"
          @handle-finalisasi="handleFinalisasiInsentif"
        />
        <template slot="footer">
          <a-button size="large" key="back" @click="toggleModalManageInsentif">
            Kembali
          </a-button>
          <a-button size="large" key="submit" type="primary" @click="handleOkManageInsentif" :class="statusInsentif ? 'd-none' : ''">Ubah
          </a-button>
        </template>
      </a-modal>
      <div>
        <a-input
          v-model="insentifSearch"
          @keyup="searchInsentif"
          size="large"
          class="mb-4"
          style="width: 20%"
          placeholder="Cari Nama Insentif..."
        />
        <a-table
          :columns="insentifColumns"
          :data-source="insentifDataTable"
          :pagination="pagination"
          :loading="loadingTable"
          @change="handleTableChangeInsentif"
          bordered
        >
          <div slot="aksi" slot-scope="value, record">
            <a-button
              @click.prevent="toggleModalManageInsentif('manage', record)"
              class="text-danger border border-danger"
              size="large"
              icon="edit"
            >Manage
            </a-button>
          </div>
        </a-table>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
const formInsentif = () => import('@/components/app/AdminKeuangan/FormInsentif')

const insentifColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    scopedSlots: { customRender: 'no' },
    width: 80,
    align: 'center',
    sorter: true,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    width: 300,
    align: 'center',
    sorter: true,
  },
  {
    title: 'Tanggal Pembayaran',
    dataIndex: 'tanggalPembayaran',
    key: 'tanggalPembayaran',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'tanggalPembayaran' },
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'status' },
    sorter: true,
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    width: 150,
    align: 'center',
  },
]

const intensifPegawaiColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    scopedSlots: { customRender: 'no' },
    align: 'center',
    width: 80,
  },
  {
    title: 'Nama Pegawai',
    dataIndex: 'namaPegawai',
    scopedSlots: { customRender: 'namaPegawai' },
    align: 'center',
    width: 320,
  },
  {
    title: 'NIR Pegawai',
    dataIndex: 'nirPegawai',
    scopedSlots: { customRender: 'nirPegawai' },
    align: 'center',
    width: 300,
  },
  {
    title: 'Total / Nominal Insentif',
    dataIndex: 'totalInsentif',
    scopedSlots: { customRender: 'totalInsentif' },
    align: 'center',
    width: 300,
  },
]

export default {
  components: {
    formInsentif,
  },
  data() {
    return {
      insentifColumns,
      intensifPegawaiColumns,
      insentifDataTable: [],
      pagination: {},
      insentifPage: 'all',
      insentifSearch: '',
      insentifOrder: '',
      insentifSortBy: '',
      statusInsentif: false,
      loadingTable: false,
      visibleModalManageInsentif: false,
      dataInsentif: {
        id: null,
        nama: null,
        tanggalPembayaran: null,
        status: null,
      },
      selectedInsentif: null,
      dataInsentifPegawai: [],
    }
  },
  methods: {
    handleTableChangeInsentif(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'nama') {
        sortBy = 'nama'
      } else if (sorter.field === 'tanggalPembayaran') {
        sortBy = 'tanggal_pembayaran_insentif'
      } else if (sorter.field === 'status') {
        sortBy = 'status'
      } else {
        sortBy = ''
      }
      this.fetchDataInsentif({
        page: pagination.current,
        order,
        sortBy,
        search: this.insentifSearch,
      })
    },
    searchInsentif() {
      if (this.insentifSearch.length > 2) {
        this.fetchDataInsentif({
          search: this.insentifSearch,
        })
      } else {
        this.fetchDataInsentif({
          search: this.insentifSearch,
        })
      }
    },
    handleAllLoading (boolean) {
      this.loadingTable = boolean
    },
    fetchDataInsentif(
      params = {
        order: this.insentifOrder,
        sortBy: this.insentifSortBy,
        search: this.insentifSearch,
        page: this.insentifPage,
      },
    ) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminKeuangan/GET_INSENTIF_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          let no = 1
          this.insentifDataTable = res.data.map((row) => {
            return {
              key: row.id,
              no: no++,
              nama: row.nama,
              tanggalPembayaran: row.tanggal_pembayaran_insentif,
              status: row.status.toUpperCase(),
            }
          })
          this.handleAllLoading(false)
        })
    },
    toggleModalManageInsentif(status, data) {
      // console.log('status, data :>> ', status, data)
      this.visibleModalManageInsentif = !this.visibleModalManageInsentif
      if (status === 'manage') {
        this.selectedInsentif = data.key
        const b4Moment = data.tanggalPembayaran
        this.dataInsentif = {
          id: data.key,
          nama: data.nama,
          tanggalPembayaran: moment(b4Moment, 'YYYY-MM-DD'),
          status: data.status,
        }
        this.statusInsentif = false
        this.fetchDataInsentifPegawai()
      }
    },
    handleOkManageInsentif() {
      this.handleAllLoading(true)
      this.$confirm({
        title: 'Peringatan!!',
        content: (
          <div>
            Apakah anda yakin ingin mengubah data insentif pegawai?
          </div>
        ),
        onOk: () => {
          this.$store
            .dispatch('adminKeuangan/UPDATE_INSENTIF_PEGAWAI', {
              data: this.dataInsentifPegawai,
              id: this.selectedInsentif,
            })
            .then((res) => {
              // console.log('res :>> ', res)
              this.handleAllLoading(false)
              if (res.isSuccess) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Berhasil ubah insentif pegawai',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Gagal ubah insentif pegawai',
                })
              }
              this.fetchDataInsentif()
              this.visibleModalManageInsentif = false
            })
        },
        onCancel: () => {
          this.handleAllLoading(false)
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Ubah Sekarang',
        cancelText: 'Batal',
      })
    },
    fetchDataInsentifPegawai() {
      const id = this.selectedInsentif
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminKeuangan/GET_INSENTIF_PEGAWAI_BY_ID_INSENTIF', { id })
        .then((res) => {
          // console.log('res', res)
          let no = 1
          if (res.statusInsentif === 'published_finance') {
            this.statusInsentif = true
          } else {
            this.statusInsentif = false
          }
          // console.log('this.statusInsentif :>> ', this.statusInsentif)
          this.dataInsentifPegawai = res.data.map((row) => {
            return {
              no: no++,
              key: row.key,
              namaPegawai: row.namaPegawai,
              nirPegawai: row.nirPegawai,
              totalInsentif: row.totalInsentif,
            }
          })
          this.handleAllLoading(false)
        })
    },
    handleChange (payload) {
      // console.log('payload :>> ', payload)
      const { value, type, column, data } = payload
      if (type === 'all') {
        for (let i = 0; i < this.dataInsentifPegawai.length; i++) {
          this.dataInsentifPegawai[i][column] = value
        }
      } else {
        const target = this.dataInsentifPegawai.filter(el => el.key === data.key)[0]
        if (target) {
          target[column] = value
          // console.log('target :>> ', target)
        }
      }
      // console.log('this.dataInsentifPegawai :>> ', this.dataInsentifPegawai)
    },
    handleFinalisasiInsentif(key) {
      // console.log('key :>> ', key)
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminKeuangan/FINALISASI_INSENTIF', { id: key })
        .then((res) => {
          // console.log('res', res)
          if (res) {
            this.$notification.success({
              message: 'SUKSES',
              description: 'Berhasil finalisasi data insentif',
            })
            this.statusInsentif = true
          } else {
            this.$notification.error({
              message: 'ERROR',
              description: 'Gagal finalisasi data insentif',
            })
            this.statusInsentif = false
          }
          this.fetchDataInsentif()
          this.visibleModalManageInsentif = false
          this.handleAllLoading(false)
        })
    },
  },
  mounted () {
    this.fetchDataInsentif()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
.modal-shift{
  .ant-modal-wrap {
    .ant-modal {
      width: 95% !important;
      // height: 95% !important;
    }
  }
}
</style>
